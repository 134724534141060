import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={"/vision.jpg"} className="App-logo" alt="logo" style={{ filter: "drop-shadow(0 0 15px white)" }} />
        <p>
          사이트 준비 중입니다.
        </p>
      </header>
    </div>
  );
}

export default App;
